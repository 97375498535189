import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CanActivatePathDirective } from 'src/app/directives/can-activate-path.directive';
import { ExpandActiveNavigationDirective } from 'src/app/directives/expand-active-navigation.directive';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { CardComponent } from 'src/app/components/shared/card/card.component';
import { MatBadgeModule } from '@angular/material/badge';
import { of, map, Observable } from 'rxjs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from 'src/app/components/layout/notification-dialog/notification-dialog.component';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from 'src/app/shared/services/notifications.service';
import { Notification } from 'src/app/models/notification';
import { SnackbarService } from 'src/app/shared/utils/snackbar/snackbar.service';

@Component({
  selector: 'app-notification-popup',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RowComponent,
    ColComponent,
    AppMaterialModule,
    CanActivatePathDirective,
    ExpandActiveNavigationDirective,
    MatBadgeModule,
    CardComponent,
    ScrollingModule,
  ],
  templateUrl: './notification-popup.component.html',
  styleUrl: './notification-popup.component.scss',
})
export class NotificationPopupComponent implements OnInit, OnChanges {
  swPush = inject(SwPush);
  router = inject(Router);

  notificationService = inject(NotificationService);
  snackbarService = inject(SnackbarService);

  notifications: Notification[] = [];
  page: number = 1;
  pageCount: number = 0;
  numberOfUnRead: number = 0;

  constructor() {
    this.swPush.notificationClicks.subscribe((event) => {
      const { url } = event.notification.data;
      try {
        const urlObj = new URL(url);
        if (urlObj.origin === window.location.origin) {
          console.log('Navigating to', urlObj.pathname);
          this.router.navigate([urlObj.pathname]);
        } else {
          console.log('Opening new tab for', url);
          window.open(urlObj, '_blank');
        }
      } catch (e) {
        this.router.navigate([url]);
      }
    });

    this.sortedNotifications$ = of(this.notifications).pipe(
      map((notifications) => {
        return notifications.sort((a, b) => {
          if (a.hasRead === b.hasRead) {
            return 0;
          }
          return a.hasRead ? 1 : -1;
        });
      })
    );
  }

  readonly dialog = inject(MatDialog);
  cdr = inject(ChangeDetectorRef);
  sortedNotifications$: Observable<any>;
  pushNotificationsGranted: boolean = false;

  ngOnInit() {
    this.checkNotificationPermissionValue();
    this.notificationService.getNotifications(this.page, 5).subscribe(
      (data) => {
        this.notifications = data.data;
        this.pageCount = data.pageCount;
        console.log('This is the noptiofication response:', data);
        console.log('These are just the notifications:', data.data);
        this.sortedNotifications$ = this.getSortedNotifications();
        this.countUnreadNotifications();
      },
      (error) => {
        console.error('Error fetching notifications:', error);
      }
    );
  }

  ngOnChanges() {
    this.checkNotificationPermissionValue();
  }

  checkNotificationPermission(): string {
    return Notification.permission;
  }

  checkNotificationPermissionValue() {
    const permission = this.checkNotificationPermission();
    if (permission === 'granted') {
      this.pushNotificationsGranted = true;
      console.log('Permission granted for notifications.');
    } else if (permission === 'denied') {
      console.log('Permission denied for notifications.');
      this.pushNotificationsGranted = false;
    } else {
      console.log('Permission not yet decided for notifications.');
    }
  }

  openNotificationDialog() {
    const dialogRef = this.dialog.open(NotificationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.checkNotificationPermissionValue();
        this.cdr.detectChanges();
      }
    });
  }

  newNotifications() {
    this.notificationService.getNotifications(this.page + 1, 5).subscribe(
      (data) => {
        if (data.data.length <= 0) {
          this.snackbarService.open({
            type: 'warning',
            message: 'Keine weiteren Benachrichtigungen.',
          });
        } else {
          this.notifications.push(...data.data);
          this.pageCount = data.pageCount;
          this.sortedNotifications$ = this.getSortedNotifications();
          this.page++;
          this.countUnreadNotifications();
        }
      },
      (error) => {
        console.error('Error fetching notifications:', error);
      }
    );
    // Update sortedNotifications$
  }

  countUnreadNotifications(): void {
    this.numberOfUnRead = this.notifications.filter(
      (notification) => !notification.hasRead
    ).length;
  }

  getSortedNotifications(): Observable<any> {
    return of(this.notifications).pipe(
      map((notifications) => {
        return notifications.sort((a, b) => {
          if (a.hasRead === b.hasRead) {
            return 0;
          }
          return a.hasRead ? 1 : -1;
        });
      })
    );
  }
}
