import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContractTermsService } from 'src/app/shared/services/contract-terms.service';
import { map, of, switchMap } from 'rxjs';
import { BannerComponent } from 'src/app/components/shared/banner/banner.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-contract-term-banner',
  standalone: true,
  imports: [CommonModule, BannerComponent, RouterModule],
  templateUrl: './contract-term-banner.component.html',
  styleUrls: ['./contract-term-banner.component.scss'],
})
export class ContractTermBannerComponent {
  authService = inject(AuthService);
  contractTermsService = inject(ContractTermsService);

  isMissingRequiredContractTerms$ =
    this.authService.isMissingRequiredContractTerms$;

  contractTermsAreBeingValidated$ = this.authService.company$
    .pipe(
      switchMap((company) => {
        if (!company) return of(undefined);
        return this.contractTermsService.getOriginalContractTermsForCompany(
          company.id
        );
      })
    )
    .pipe(map((terms) => terms?.status === 'PENDING'));
}
