import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private headers = {
    'Content-Type': 'application/json',
  };
  private http = inject(HttpClient);

  public subscribe(pushSubscription: PushSubscription): Observable<Response> {
    const key = pushSubscription.getKey
      ? pushSubscription.getKey('p256dh')
      : '';
    const auth = pushSubscription.getKey ? pushSubscription.getKey('auth') : '';

    return this.http.post<Response>(
      environment.quarkusApiUrl + '/notifications/subscribe',
      {
        endpoint: pushSubscription.endpoint,
        key: key
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
          : '',
        auth: auth
          ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth)))
          : '',
      },
      { headers: this.headers }
    );
  }

  getNotifications(page: number, size: number): Observable<any> {
    let params = new HttpParams().set('page', page).set('size', size);
    return this.http.get<any>(`${environment.quarkusApiUrl}/notifications/`, {
      params,
    });
  }
}
