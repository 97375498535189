<h2 mat-dialog-title>Push-Benachrichtungen erhalten</h2>
<mat-dialog-content class="mat-typography">
  <app-col lg="6">
    <p class="mb-4 deal-description">
      Um das Beste aus unserer Plattform herauszuholen, empfehlen wir Ihnen,
      Push-Benachrichtigungen zu aktivieren. So bleiben Sie immer auf dem
      Laufenden über wichtige Updates und Nachrichten.
    </p>

    <!-- <ul class="list">
            <li>Keine Kosten!</li>
            <li>Frühzeitig von Plattform Funktionen profitieren</li>
            <li>Mitgestalten durch Feedback</li>
          </ul> -->
  </app-col>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button
    color="primary"
    mat-raised-button
    (click)="subscribeToNotifications()"
  >
    Push-Benachrichtungen erhalten
  </button>
</mat-dialog-actions>
