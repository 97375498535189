<ng-container *ngIf="authService.isVerified$ | async">
  <app-banner *ngIf="isMissingRequiredContractTerms$ | async">
    Um Angebote abzugeben müssen Sie Ihre&nbsp;
    <a routerLink="/betrieb/vertragsbedingungen">AGB vervollständigen</a>
  </app-banner>

  <app-banner
    *ngIf="
      (isMissingRequiredContractTerms$ | async) === false &&
      (contractTermsAreBeingValidated$ | async)
    "
  >
    Wir verifizieren Ihre Vertragsbedingungen und schalten Sie in Kürze zum
    Bieten frei.
  </app-banner>
</ng-container>
