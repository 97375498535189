import { Component, inject } from '@angular/core';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/shared/utils/snackbar/snackbar.service';
import { NotificationService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-notification-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, RowComponent, ColComponent],
  templateUrl: './notification-dialog.component.html',
  styleUrl: './notification-dialog.component.scss',
})
export class NotificationDialogComponent {
  constructor(
    private swPush: SwPush,
    private notificationService: NotificationService,
    private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<NotificationDialogComponent>
  ) {
    this.dateAdapter.setLocale('de');
  }

  snackbarService = inject(SnackbarService);

  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.notifications.publicKey,
      })
      .then((sub) => {
        this.notificationService.subscribe(sub).subscribe({
          next: () => {
            this.snackbarService.open({
              type: 'success',
              message: 'Push-Benachrichtigungen aktiviert.',
            });
            this.dialogRef.close(sub);
          },
          error: (err) => {
            console.error('Could not subscribe to notifications', err);
            this.snackbarService.open({
              type: 'error',
              message:
                'Push-Benachrichtigungen konnten nicht aktiviert werden.',
            });
          },
        });
      })
      .catch((err) => {
        console.error('Could not subscribe to notifications', err);
        this.snackbarService.open({
          type: 'error',
          message:
            'Push-Benachrichtigungen wurden schon mal blockiert und müssen erst zurückgesetzt werden.',
        });
      });
  }
}
