import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CanActivatePathDirective } from 'src/app/directives/can-activate-path.directive';
import { ExpandActiveNavigationDirective } from 'src/app/directives/expand-active-navigation.directive';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreateEditDialogComponent } from 'src/app/components/shared/create-edit-dialog/create-edit-dialog.component';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { MediaMatcher } from '@angular/cdk/layout';
import { forkJoin, map } from 'rxjs';
import { NotificationPopupComponent } from 'src/app/components/layout/notification-popup/notification-popup.component';
import { DialogService } from 'src/app/shared/utils/dialog/dialog.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RowComponent,
    ColComponent,
    AppMaterialModule,
    CanActivatePathDirective,
    ExpandActiveNavigationDirective,
    NotificationPopupComponent,
  ],
})
export class SidenavComponent implements OnInit, OnDestroy {
  public url: string | ArrayBuffer;
  public environment = environment;
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public readonly keycloak: KeycloakService,
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  dialogService = inject(DialogService);

  @ViewChild('drawer') drawer!: MatDrawer;

  showNavigationLabels$ = forkJoin([
    this.authService.isWasteProducer$,
    this.authService.isWasteDisposer$,
    this.authService.isWasticsAdmin$,
  ]).pipe(
    map(([isWasteProducer, isWasteDisposer, isWasticsAdmin]) => {
      let count = 0;
      if (isWasteProducer) count++;
      if (isWasteDisposer) count++;
      if (isWasticsAdmin) count++;
      return count > 1;
    })
  );

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.mobileQuery.matches) {
        this.drawer.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  isLoggedin() {
    return this.keycloak.isLoggedIn();
  }

  public loginSession() {
    this.keycloak.login();
  }

  public logoutSession() {
    const location = `${window.location.protocol}//${window.location.host}`;
    this.keycloak.logout(location);
  }

  public register() {
    this.keycloak.register();
  }

  takeMeHome() {
    this.router.navigate(['/dashboard']);
  }

  public delete() {
    this.url = null;
  }

  receiveImageUrl($event) {
    this.url = $event;
  }

  openCreateEditAWKDialog() {
    const dialogRef = this.dialog.open(CreateEditDialogComponent, {
      data: {
        title: 'AWK Erstellen',
        content:
          'Möchten Sie ein neues AWK erstellen oder ein altes bearbeiten oder als Entwurf nutzen?',
        newRoute: '/awk/neu',
        editRoute: '/awk/list',
      },
    });
  }
}
