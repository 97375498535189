import { AfterContentChecked, Directive } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationStart, Router } from '@angular/router';

@Directive({
  selector: '[appExpandActiveNavigation]',
  standalone: true,
})
export class ExpandActiveNavigationDirective implements AfterContentChecked {
  dirty = true;

  constructor(
    private host: MatExpansionPanel,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dirty = true;
      }
    });
  }

  ngAfterContentChecked() {
    if (!this.dirty) return;
    if (this.host.expanded) return;

    const hostElement = this.host._body?.nativeElement;

    if (!hostElement) return;

    const hasActiveChildren = Boolean(hostElement.querySelector('.active'));
    if (hasActiveChildren) {
      this.host.open();
      this.dirty = false;
    }
  }
}
